:root {
    --brand-bg-color: #2c2c2c;
    --brand-txt-color: #ffffff;
    --brand-link-color: #ffffff;
 }
@import url('https://fonts.googleapis.com/css?family=Abel|Source+Code+Pro&display=swap');

body {
  color: var(--brand-txt-color);
  font-family: "Abel";
}
.header {
  width: 100%;
  color: var(--brand-txt-color);
}
a {
  color: var(--brand-link-color);
}
.room {
  letter-spacing: 0.1em;
}
.footer {
  background-color: var(--brand-bg-color);
}
#frame {
  background-color: var(--brand-bg-color);
}