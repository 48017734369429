@import url(https://fonts.googleapis.com/css?family=Abel|Source+Code+Pro&display=swap);
body {
  padding: 20px;
  margin: 0;
  height: 1vh;
}
.content {
  padding: 20px;
}
.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}
.room {
  letter-spacing: 0.1em;
}
.header {
  height: 4%;
  min-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.title {
  position: absolute;
  left: 0.5em;
  top: 50%;
  transform: translateY(-50%);
}
.footer {
  position: absolute;
  width: 100%;
  height: 4%;
  bottom: 0;
  left: 0;
}
.date {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1em;
}
.plug {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.made {
  position: absolute;
  margin-right: 1em;
  right: 8.5em;
  top: 50%;
  transform: translateY(-50%);
}
.daily {
  position: absolute;
  margin-right: 1em;
  right: 2.25em;
  top: 50%;
  transform: translateY(-50%);
}
img.github {
  position: absolute;
  height: 1.25em;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 1em;
}
img.logo-header {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 1em
}
#frame {
  background-size: 30%;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
@media only screen and (max-width: 600px) {
  .title, footer {
    font-size: 90%;
  }
  .daily {
    font-size: 50%;
    margin-right: 3em;
  }
}
html, body {
  overflow-x: hidden;
  overflow-y: hidden;
}
:root {
    --brand-bg-color: #2c2c2c;
    --brand-txt-color: #ffffff;
    --brand-link-color: #ffffff;
 }

body {
  color: #ffffff;
  color: var(--brand-txt-color);
  font-family: "Abel";
}
.header {
  width: 100%;
  color: #ffffff;
  color: var(--brand-txt-color);
}
a {
  color: #ffffff;
  color: var(--brand-link-color);
}
.room {
  letter-spacing: 0.1em;
}
.footer {
  background-color: #2c2c2c;
  background-color: var(--brand-bg-color);
}
#frame {
  background-color: #2c2c2c;
  background-color: var(--brand-bg-color);
}
